import { matchPath, match } from 'react-router';
import { ReactElement } from 'react';
import { TFunction } from 'i18next';
import { paths } from 'src/routes/Constants';
import { NotificationPreferences } from 'src/pages/Account/NotificationPreferences';
import { Features } from 'src/components/Feature/constants';
import BillingPreferences from './BillingPreferences';
import LocalizationPreferences from './LocalizationPreferences';
import LeadAlertPreferences from './LeadAlertPreferences';

interface SettingsPanel {
  component: ReactElement;
  expanded: boolean;
  key: string;
  path: string;
  title: (t: TFunction) => string;
  shouldRender?: (features: Features) => boolean;
}

export const settingsPanels: SettingsPanel[] = [
  {
    component: <BillingPreferences />,
    expanded: false,
    key: 'BillingPreferences',
    path: paths.account.billing,
    title: t => t('account:titles.billing')
  },
  {
    component: <NotificationPreferences />,
    expanded: false,
    key: 'NotificationPreferences',
    path: paths.account.notifications,
    title: t => t('account:titles.notifications'),
    shouldRender: features => {
      return features?.showNotificationPanel;
    }
  },
  {
    component: <LeadAlertPreferences />,
    expanded: false,
    key: 'LeadAlertPreferences',
    path: paths.account.leadAlertPreferences,
    title: t => t('account:titles.leadAlertPreferences'),
    shouldRender: features => {
      return features?.contacts || features?.leadNotifications;
    }
  },
  {
    component: <LocalizationPreferences />,
    expanded: false,
    key: 'LanguagePreferences',
    path: paths.account.localization,
    title: t => t('account:titles.localization')
  }
];

export const getSettingsPanels = (pathname: string) => {
  // Note this is how we are doing route based Accordions
  return settingsPanels.map(panel => {
    const pathToMatch: match = {
      exact: false,
      path: paths.account,
      strict: false
    } as any;

    switch (panel.path) {
      case paths.account.billing:
        pathToMatch.path = paths.account.billing;
        break;
      case paths.account.notifications:
        pathToMatch.path = paths.account.notifications;
        break;
      case (paths.account as any).instagram:
        pathToMatch.path = (paths.account as any).instagram;
        break;
      case paths.account.localization:
        pathToMatch.path = paths.account.localization;
        break;
      case paths.account.leadAlertPreferences:
        pathToMatch.path = paths.account.leadAlertPreferences;
        break;
      default:
        pathToMatch.path = paths.account as any;
        break;
    }

    const match = matchPath(pathname, pathToMatch);

    // set to expanded if the path matches the current route
    const panelsWithExpandedState = {
      ...panel,
      expanded: !!match
    };

    return panelsWithExpandedState;
  });
};
