import { get, isFunction } from 'lodash';
import { t } from 'i18next';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { generateLinkPath } from 'src/routes/RouteUtil';
import { useFeatures } from 'src/components/Feature/Feature';
import Heading from 'src/components/PageElements/Heading';
import { History, Location } from 'history';
import { getSettingsPanels } from './utilities';

interface AccountProps {
  location: Location;
  history: History;
}

const Account = ({ location: { pathname }, history }: AccountProps) => {
  const features = useFeatures();

  return (
    <Box
      sx={{
        width: '100%'
      }}
    >
      <Heading
        title={t('account:headings.accountSettings')}
        subTitle={t('account:headings.accountSettingsSubTitle')}
        pageTitle={t('account:headings.accountSettings')}
      />

      {getSettingsPanels(pathname)
        .filter(panel => {
          const shouldRender = get(panel, 'shouldRender');

          if (isFunction(shouldRender)) {
            return shouldRender(features);
          }

          return true;
        })
        .map(panel => {
          return (
            <Accordion
              expanded={panel.expanded}
              key={panel.key}
              onChange={() => history.push(generateLinkPath(panel.path))}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                {...(panel.expanded && { sx: { mb: 1 } })}
              >
                <Typography variant="subtitle2">{panel.title(t)}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {panel.expanded && panel.component}
              </AccordionDetails>
            </Accordion>
          );
        })}
    </Box>
  );
};

export default Account;
